import React from "react";
import css from './offeringAndPricing.module.scss';
import {graphql, useStaticQuery} from "gatsby";
import {useTranslation} from "react-i18next";
import MainButton, {mainButtonArrow, mainButtonFill} from "../../shared/MainButton/MainButton";
import TimberIdLogo from "../../../images/logos/Timber_ID_logo.svg";
import {getLink, getRoutes} from "../../shared/routes";
import {Link} from 'gatsby';

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_offering_section"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1090, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const OfferingAndPricing = () => {
    const {t} = useTranslation();

    const renderSection = (index, title, text, additionalText) => {

        return (
            <div style={{position: 'relative'}}>
                <div className={css.sectionTitle}>
                    <div className={css.title}> {title}</div>
                </div>
                <div className={css.section}>
                    <div className={css.sectionContentWrapper}>
                        <div className={css.sectionText}>
                            {text}
                        </div>
                    </div>
                </div>
                <div className={additionalText ? css.text2 : css.text3}>{additionalText}</div>
            </div>
        );
    };

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <div className={css.text}>
                    All supply chains stakeholders have a vital interest in a highly reliable and verifiable information
                    flow, linked to the traded products.
                    <br/><br/>
                    Therefore, solution affordability is essential.
                </div>
                <div className={css.logo}><img
                    src={TimberIdLogo} alt={'TimberId Logo'}
                    style={{transform: `scale(${1})`}}/></div>

                <div className={css.sectionsWrapper}>
                    {renderSection(1, 'Annual License Access Fee', <div>
                        <div>Price Range</div>
                        <div> €100* - €12K* - And More*</div>
                    </div>)}
                    {renderSection(1, 'Operational Fees (Pay-As-You-Use)', <div>
                        <div>File Storage,</div>
                        <div>Processing And Transfers</div>
                        <div>Based On Customer Cloud Use</div>
                    </div>)}
                    {renderSection(1, 'Implementation', <div>
                        <div>Implementation</div>
                        <div>Within 1 Day</div>
                    </div>)}
                    {renderSection(1, 'Dcode', <div>
                        <div>Dcode Marking</div>
                        <div>Estimated Unit Cost</div>
                        <div>Range EUR 0,02* - 0,03*</div>
                    </div>, <span><div>* Range by Client revenues,</div><div>Deeplai T&C</div></span>)}
                </div>
                <div className={css.button}>
                    <Link
                        to={`${getLink([getRoutes().Offering])}#${getRoutes().Offering.sections.offeringSection.anchor}`}>
                        <MainButton label={"Request a proposal"} arrow={mainButtonArrow.right}
                                    fill={mainButtonFill.gray}/>
                    </Link>
                </div>
                <div className={css.text} style={{textAlign: 'center'}}>
                    Consequently, our cost-effective licence pricing model is set on a customer size base, allowing
                    everyone to benefit from the full system functionality.
                    <br/><br/>
                    The Pay-As-You-Use variable system usage, operational fees are based on customer data storage,
                    transfers and processing, all statistics and values displayed, and directly managed by a customer.
                </div>
            </div>
        </div>
    );
};

export default OfferingAndPricing;

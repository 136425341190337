import React from "react";
import css from './pricingModel.module.scss';
import Text from "../../shared/Text/Text";


const PricingModel = () => {

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <Text textIsBold={false}>
                    <span className={css.textBold}>TimberID</span> is a cohesive platform consisting of interconnected
                    functionalities and is offered as a complete solution.
                    <br/><br/>
                    As a client, you can decide to utilize the entire platform or select specific features that meet
                    your requirements.
                </Text>
            </div>
        </div>
    )
};

export default PricingModel;

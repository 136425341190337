import {graphql, useStaticQuery} from "gatsby";
import css from "./hardware.module.scss";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import {getRoutes} from "../../shared/routes";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import Text from "../../shared/Text/Text";
import React from "react";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_hardware"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`

const Hardware = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const routes = getRoutes();
    const title = routes.Offering.sections.hardware.title;

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>
                <div className={css.title}>{title}</div>
            </SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'35%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'Offering_Hardware.png', 'Offering Hardware')}
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'}>
                <SectionDesktopTitle>
                    <div className={css.title}>{title}</div>
                </SectionDesktopTitle>
                <Text textIsBold={false}>
                    Dedicated hardware units for generating and applying the Dcode are the subject of offering
                    on the basis of Customer requirements and defined volumes.</Text>
                <br/>
            </SectionTextWrapper>
        </div>
    )
}
export default Hardware;

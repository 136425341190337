import {graphql, Link, useStaticQuery} from 'gatsby';
import React from "react";
import css from './offeringMobileApps.module.scss';
import {getLink, getRoutes} from "../../shared/routes";
import {SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import BuyAppButtons, {urlTypes} from "../../shared/BuyAppButtons/BuyAppButtons";
import TimberIdLogo from "../../../images/logos/Timber_ID_logo.svg";
import DcodeLogo from "../../../images/logos/DcodeR_logo_2.svg";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_offering-mobile-apps"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const OfferingMobileApps = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));
    const routes = getRoutes();
    const title = routes.Offering.sections.mobileApps.title;

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>
                <div className={css.title}>{title}</div>
            </SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'Offering_Mobile_Apps.png', 'Offering Mobile Apps')}
                    </div>
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'}>
                <div className={css.desktopTitle}>{title}</div>
                <div className={css.container}>
                    <table>
                        <tbody>
                        <tr>
                            <td className={css.column}>
                                <div className={css.text}>User access, on the App Store</div>
                            </td>
                            <td>
                                <BuyAppButtons urlType={urlTypes.main}/>
                            </td>
                        </tr>
                        <tr>
                            <td className={css.column}>
                                <div style={{marginTop: '4px'}}>TimberID</div>
                            </td>
                            <td>
                                <Link to={getLink([getRoutes().TechnologyMobileApps])}><span className={css.logo}><img
                                    src={TimberIdLogo} alt={'TimberId Logo'}
                                    style={{transform: `scale(${1})`}}/></span></Link>
                            </td>
                        </tr>
                        <tr>
                            <td className={css.column}>
                                <div style={{marginTop: '15px'}}>Dcode Reader</div>
                            </td>
                            <td style={{paddingTop: '20px'}}>
                                <Link to={getLink([getRoutes().TechnologyMobileApps])}> <span className={css.logo2}><img
                                    src={DcodeLogo} alt={'Dcode Logo'}
                                    style={{transform: `scale(${0.8})`}}/></span></Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </SectionTextWrapper>
        </div>
    )
};

export default OfferingMobileApps;
